@keyframes hidetransition {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@-webkit-keyframes hidetransition {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

:root {
  --main-color: rgb(235, 27, 27);
  --font-color: rgb(238, 238, 238);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: rgb(31, 22, 22);
  margin: 0 3rem;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: var(--font-color);
  width: 50vw;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

ul {
  display: flex;
  list-style: none;
  color: var(--font-color);
}

.icon-container {
  position: relative;
}

.hidden-svg {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  height: 0px;
  width: 100px;
  display: flex;
  align-items: flex-end;
  transition: 1s ease;
}

.hidden-svg > a > svg {
  position: relative;
  fill: var(--main-color);
}

svg {
  fill: var(--font-color);
  width: 40px;
  height: 40px;
  transition: fill 1s ease;
  margin: 0 0 0 1rem;
}

.icon-container:hover > .hidden-svg {
  height: 50px;
  width: 100px;
  transition: 1s ease;
}

.name-container {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.main-name {
  font-size: 72px;
  background: rgb(238, 238, 238);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
}

.second-name-container {
  position: absolute;
  bottom: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  color: var(--main-color);
  transition: 1s ease;
  cursor: pointer;
}

.name-container:hover > .second-name-container {
  height: 50px;
  transition: 1s ease;
}

.second-name {
  font-size: 72px;
  z-index: 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.subtitle {
  display: flex;
  justify-content: flex-end;
  color: var(--font-color);
  text-align: justify;
  font-size: 18px;
  width: 642px;
}

.menu-container {
  display: flex;
  /* height: 80vh; */
  padding: 8rem 0 0 0;
  animation-name: fade-in;
  -webkit-animation: fade-in;
  animation-duration: 1s;
}

.link-list {
  display: inline-block;
}

.link-container {
  flex-grow: 3;
}

.project-link {
  font-size: 36px;
  width: 100%;
}

.project-preview-container {
  flex-grow: 1;
  position: relative;
  width: 50%;
}

.project-preview-img {
  position: absolute;
  width: 100%;
}

.link-block {
  display: block;
}

.in > .project-preview-img {
  transform: translateX(0);
  transition: transform 1s ease;
}

.out > .project-preview-img {
  transform: translateX(200%);
  transition: transform 1s ease;
}

.img-in {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.img-out {
  opacity: 0;
  /* transition: opacity 0.5s ease;
  animation-name: hidetransition;
  animation-timing-function: ease;
  animation-duration: 0.3s;
  -webkit-animation: hidetransition;
  -webkit-animation-timing-function: ease;
  -webkit-animation-duration: 0.3s;
  transform: translateX(-100%); */
}

.project-container {
  margin: 5rem 0 0 0;
  display: flex;
  animation-name: fade-in;
  animation-duration: 1s;
  -webkit-animation: fade-in;
  -webkit-animation-duration: 1s;
}

.blurb-container {
  width: 50vw;
  margin: 0 2rem 0 0;
}

.project-image-container {
  width: 50vw;
  position: relative;
}

.project-title {
  font-size: 32px;
  color: var(--font-color);
  margin: 0 0 2rem 0;
}

.project-blurb {
  font-size: 18px;
  color: var(--font-color);
  white-space: pre-line;
}

.project-img {
  position: absolute;
  width: 100%;
  cursor: pointer;
  transition: 0.5s ease;
}

.project-img:hover {
  transform: scale(1.05);
  transition: transform 0.5s ease;
}

.modal-img {
  animation-name: fade-in;
  animation-duration: 0.5s;
  -webkit-animation: fade-in;
  -webkit-animation-duration: 0.5s;
  width: 80vw;
}

.github-project-link {
  margin: 2rem 0;
  font-size: 32px;
  display: flex;
}

.external-link {
  font-size: 18px;
}

.link-margin {
  margin: 0 2rem 0 0;
}

.css-fan {
  color: var(--main-color);
}

.about-text {
  font-size: 18px;
  color: var(--font-color);
}

.about-container {
  display: flex;
  margin: 8rem 0;
  justify-content: center;
}

.about-text-container {
  width: 40vw;
}

.photo-container {
  position: relative;
  width: 40vw;
  height: 40vh;
  display: flex;
  justify-content: center;
}

.photo {
  position: absolute;
  height: 100%;
  border-radius: 50%;
}

@media all and (max-width: 855px) {
  .main-name {
    font-size: 40px;
  }
  .second-name {
    font-size: 40px;
  }
  .name-container:hover > .second-name-container {
    height: 30px;
    transition: 1s ease;
  }
  .subtitle {
    width: 355px;
  }

  .photo-container {
    width: 20vw;
    height: 20vh;
    justify-content: flex-start;
    margin: 0 0 0 2rem;
  }

  .external-link {
    font-size: 18px;
  }
}

@media all and (max-width: 565px) {
  .main-name {
    font-size: 22px;
  }

  .second-name {
    font-size: 22px;
  }

  .name-container:hover > .second-name-container {
    height: 15px;
    transition: 1s ease;
  }

  .project-link {
    font-size: 22px;
  }

  .subtitle {
    width: 196px;
    font-size: 10px;
  }

  .photo-container {
    margin: 0 0 2rem 0;
    justify-content: center;
  }
  body {
    overflow-x: hidden;
  }

  .about-text-container {
    width: 100%;
  }

  .about-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}
